const translator = {
  init() {
    $('.translate').on('click', function(e){
      e.preventDefault();
      var type = $(this).data('type');
      var source = $(this).data('source');
      var language = $(this).data('language');
      var text;
      if (type === 'txt') {
        text = $('#' + source).val();
      } else {
        text = tinyMCE.get(source).getContent();
      }

      var target = $.makeArray($(this).data('target').split(' '));

      // alert about overwriting if text_eu already present
      var confirmed = true;
      if ($('#' + target[0]).val().length > 0) {
        confirmed = confirm('¿Está seguro? El contenido actual será sustituido por la traducción automática.')
      }
      
      if (confirmed) {
        $.ajax({
          url: $(this).attr('href'),
          data: {text: text, type: type, language: language},
          dataType: 'json',
          beforeSend: function(){
            $("#ajax_loader").css("display", "block");
          }
        }).done(function(data) {
          $("#ajax_loader").css("display", "none");
          $.each(target, function(index, elem){
            if (type === 'txt' || elem.match(/original/)) {
              $('#' + elem).val(data);
            } else {
              tinyMCE.get(elem).setContent(data);
            }
          });
          $('#translation_revised_form_group').removeClass('d-none');
        }).fail(function(data) {
          $("#ajax_loader").css("display", "none");
          alert('Error obteniendo la traducción desde Itzuli')
        });
      }
    });
  }
}

export default translator