$.fn.enable = function (){
  return this.each(function() {
    $(this).show();
    $(this).find('input,select').each(function(){
      $(this).removeAttr('disabled');
    })
  });
}

$.fn.disable = function (){
  return this.each(function() {
    $(this).hide();
    $(this).find('input,select').each(function(){
      $(this).attr('disabled', 'disabled');
    })
  });
}

const indicatorsForm = {
  init() {
    if ($('#indicator_form').length > 0) {
      if($('#a_indicator_indicator_type').length > 0){
        indicatorsForm.toggleIndicatorTypeFields();
        $('#a_indicator_indicator_type').on('change', indicatorsForm.toggleIndicatorTypeFields);
      }
    }
  },
  toggleIndicatorTypeFields(){
    let indicator_type = $('#a_indicator_indicator_type').val()
    switch (indicator_type) {
    case 'numeric':
      $('#indicator_type_numeric').enable();
      $('#indicator_type_years').disable();
      $('#indicator_type_ranking').disable();
      $('#indicator_type_phases').disable();
      $('#indicator_type_boolean').disable();
      $('.indicator_type_example').disable();
      $('#indicator_type_numeric_example').enable();
      break;
    case 'years':
      $('#indicator_type_numeric').disable();
      $('#indicator_type_years').enable();
      $('#indicator_type_ranking').disable();
      $('#indicator_type_phases').disable();
      $('#indicator_type_boolean').disable();
      $('.indicator_type_example').disable();
      $('#indicator_type_years_example').enable();
      break;
    case 'ranking':
      $('#indicator_type_numeric').disable();
      $('#indicator_type_years').disable();
      $('#indicator_type_ranking').enable();
      $('#indicator_type_phases').disable();
      $('#indicator_type_boolean').disable();
      $('.indicator_type_example').disable();
      $('#indicator_type_ranking_example').enable();
      break;
    case 'phases':
      $('#indicator_type_numeric').disable();
      $('#indicator_type_years').disable();
      $('#indicator_type_ranking').disable();
      $('#indicator_type_phases').enable();
      $('#indicator_type_boolean').disable();
      $('.indicator_type_example').disable();
      $('#indicator_type_phases_example').enable();
      break;
    case 'boolean':
      $('#indicator_type_numeric').disable();
      $('#indicator_type_years').disable();
      $('#indicator_type_ranking').disable();
      $('#indicator_type_phases').disable();
      $('#indicator_type_boolean').enable();
      $('.indicator_type_example').disable();
      $('#indicator_type_boolean_example').enable();
      break;
    default:
      $('#indicator_type_numeric').disable();
      $('#indicator_type_years').disable();
      $('#indicator_type_ranking').disable();
      $('#indicator_type_phases').disable();
      $('#indicator_type_boolean').disable();
      $('.indicator_type_example').disable();
    }
  }
}

export default indicatorsForm
