// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require popper

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import resources from './resources'
import autocompleter from './autocompleter'
import translator from './translator'
import indicators from '../plugins/indicators'
import BSN from "bootstrap.native";

require("jquery")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.add_fields = function(link, association, content){
  var new_id = new Date().getTime();
  var regexp = new RegExp("new_" + association, "g")
  var container = $('#' + $(link).data('container'));
  $(container).append(content.replace(regexp, new_id));
}

window.remove_fields = function(link){
  $(link).prev("input[type=hidden]").val("1");
  $(link).closest(".item_field").hide();
  $(link).closest(".item_field").toggleClass('d-none').toggleClass('d-flex');       
}

$( document ).on('turbolinks:load', function() {
  BSN.initCallback(document.body)
  autocompleter.initChosen();
  translator.init();
  indicators.init();
  resources.bindShowLink();
  resources.bindEditLink();
  resources.bindNewLink();
  $('a.toggle-featured').on('ajax:success', updateFeatured)
});

window.updateFeatured = function(event){
  $(event.target).toggleClass('btn-custom').toggleClass('btn-outline-custom')
  $(event.target).find('i').toggleClass('fas').toggleClass('far')
  $(event.target).find('span.featured-text').toggleClass('d-none')
}
