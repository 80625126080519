import Rails from "@rails/ujs"
import BSN from "bootstrap.native/dist/bootstrap-native.esm.min.js";

const resources = {
  getLocale(){
    var locale = $('body').data('locale');
    return locale;
  },
  modal() {
    return new BSN.Modal('#resource_modal', { backdrop: true })
  },
  // render resources/_show in modal
  bindShowLink() {
    $('.link_to_resource').on('dblclick', function(e){
      var promise = resources.getResourceHTML(e);
      promise.done(function(html){
        resources.bindEditLink();
      });
    })
  },
  // render edit/_show in modal
  bindEditLink() {
    $('.link_to_edit_resource').on('click', function(e){
      // var promise = resources.getResourceHTML(e, 'edit');
      var promise = resources.getResourceHTML(e, $(this).attr('href'));
      promise.done(function(html){
        resources.bindFormAction();
      });
    })
  },
  bindNewLink() {
    $('.link_to_new_resource').on('click', function(e){
      var promise = resources.getResourceHTML(e, $(this).attr('href'));
      promise.done(function(html){
        resources.bindFormAction();
      });
    })
  },
  // submit edit/_form with xhr
  bindFormAction() {
    // $('input.tagsinput').setTagAutocompleter();
    $('form.edit_resource').on('submit', function(e){
      var promise = resources.postResource(e);
      promise.done(function(html){
        resources.bindEditLink();
      }).fail(function(){
        resources.bindFormAction();
      });
    })
  },
  getResourceHTML(event, resource_url) {
    event.preventDefault();
    // var resource_url = resources.resourceUrl(event.currentTarget, action);
    var params = $(event.currentTarget).data('params');
    return $.ajax({
      type: 'GET',
      url: resource_url,
      data: params,
      dataType: 'html',
      success: function(html){
        resources.updateModalHTML(html);
      }
    })
  },
  postResource(event) {
    event.preventDefault();
    var resource_url;
    if (event.target.action.length > 0){
      resource_url = event.target.action; 
    } else {
      resource_url = resources.resourceUrl(event.currentTarget);
    }
    return $.ajax({
      type: 'POST',
      url: resource_url,
      dataType: 'html',
      data: $(event.currentTarget).serialize(),
      success: function(html){
        var parent = $(event.currentTarget).data('parent')
        $('#' + parent).html(html)
        resources.modal().hide();
        // resources.updateModalHTML(html);
      }
    })
  },
  resourceUrl(elem, action){
    var locale = resources.getLocale();
    var resource_type = $(elem).data('resourceType');
    var resource_id = $(elem).data('resourceId');
    // ES6: var resource_url = ['', locale, resource_type, resource_id, action].filter((a) => {return a !== undefined}).join('/');
    var url_params;
    if (action === undefined) {
      url_params = ['', locale, resource_type, resource_id, action]
    } else {
      url_params = ['', locale, 'admin', resource_type, resource_id, action]
    }
    var resource_url = url_params.filter(function(a){
      return a !== undefined;
    }).join('/');
    return resource_url;
  },
  updateModalHTML(html){
    // var modal = $('#resource_modal');
    // modal.html(html);
    // if (!modal.is(':visible')) {
    //   modal.modal('show');
    //   modal.modal('handleUpdate');
    // }
    // var modal = new BSN.Modal('#resource_modal', { backdrop: true, content: html });
    resources.modal().setContent(html)
    resources.modal().show();
  }
}

export default resources
