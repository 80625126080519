require("chosen-js")
require("chosen-js/chosen.css")
require("../stylesheets/bootstrap4_chosen.css")

const autocompleter = {
  initChosen() {
    $('.chosen-select').chosen({
      no_results_text: "Sin resultados para",
      placeholder_text_single: " ",
      placeholder_text_multiple: $(this).data('placeholder')
    })
  },
}

export default autocompleter